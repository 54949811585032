export default {
  isSearchOpen: false,
  isMiniCartOpen: false,
  isMiniCartLoaded: false,
  previouslyFocusedElement: null,
  headerSearchToggle: null,
  activeVariant: {},
  selectedOptions: [],
  productReviewsLoaded: false,
  hideJen: false,
  cart: window.BARREL.cart,
  modalTarget: null
}
